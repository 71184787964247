<template>
    <div class="helpList">
        <div class="helpList__head">
            <div class="helpList__col">{{ $t("help.tickets.table.created") }}</div>
            <div class="helpList__col">{{ $t("help.tickets.table.topic") }}</div>
            <div class="helpList__col">{{ $t("help.tickets.table.status") }}</div>
            <div class="helpList__col"></div>
        </div>

        <div class="helpList__row" v-for="ticket in tickets" :key="ticket.id">
            <div class="helpList__col" :class="[ticket.hasNew && 'new']">
                <div class="helpList__mobile-desc">{{ $t("help.tickets.table.created") }}</div>
                {{ ticket.date }}
            </div>
            <div class="helpList__col" :class="[ticket.hasNew && 'new']">{{ ticket.topic }}</div>
            <div class="helpList__col">
                <div class="helpList__mobile-desc">{{ $t("help.tickets.table.status") }}</div>
                {{ ticket.status }}
            </div>
            <div class="helpList__col">
                <router-link :to="{ name: 'helpticket', params: { id: ticket.id } }" class="helpList__cta">
                    <UiButton :text="$t('help.tickets.details')" size="sm" textColor="#ffffff" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
    computed: {
        ...mapGetters("ticket", ["tickets"])
    }
}
</script>

<style lang="scss" scoped>
.helpList {
    &__head {
        display: flex;
        color: var(--color-silver);
        .helpList__col {
            padding-top: 0;
        }
    }
    &__row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(#ffffff, 0.2);
    }
    &__col {
        position: relative;
        width: 100%;
        flex: 0 0 auto;
        min-width: 1px;
        padding: 10px 10px;
        font-size: 16px;
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4) {
            flex-basis: 20%;
            max-width: 20%;
        }
        &:nth-child(2) {
            flex-basis: 40%;
            max-width: 40%;
        }
        &:first-child {
            padding-left: 20px;
        }
        &:last-child {
            padding-right: 0;
        }
        &.new {
            &::before {
                display: inline-block;
                content: " ";
                position: absolute;
                left: 0px;
                top: 16px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--color-emerald);
            }
        }
    }
    &__mobile-desc {
        display: none;
    }
    &__cta {
        display: block;
    }
}

@media (max-width: 767px) {
    .helpList {
        &__head {
            display: none;
        }
        &__row {
            flex-direction: column;
            align-items: flex-start;
            padding: 15px 0;
        }
        &__col {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100% !important;
            max-width: 100% !important;
            font-size: 14px;
            margin-bottom: 10px;
            &:nth-child(1) {
                order: 2;
                &.new {
                    padding-left: 0;
                }
                &::before {
                    display: none;
                }
            }
            &:nth-child(2) {
                order: 1;
                font-size: 16px;
                margin-bottom: 15px;
            }
            &:nth-child(3) {
                order: 3;
            }
            &:nth-child(4) {
                order: 4;
                margin-bottom: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            &.new {
                padding-left: 16px;
                &::before {
                    left: 0px;
                    top: 6px;
                }
            }
        }
        &__mobile-desc {
            display: block;
            width: 80px;
            padding-right: 12px;
            color: #c5c5c5;
        }
        &__cta {
            margin-top: 5px;
            button {
                min-width: 124px;
            }
        }
    }
}
</style>
